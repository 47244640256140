export default {
  namespaced: true,
  state: {
    currentUser: undefined
  },
  getters: {
    currentUser (state) {
      return state.currentUser
    }
  },
  mutations: {
    SET_USER (state, newValue) {
      state.currentUser = newValue
    }
  }
}
