export default {
    namespaced: true,
    state: {
      currentAOI: undefined
    },
    getters: {
        currentAOI (state) {
          return state.currentAOI
        }
    },
}
  