export default {
  namespaced: true,
  state: {
    currentDataset: []
  },
  getters: {
    currentDataset (state) {
      return state.currentDataset
    }
  }
}
