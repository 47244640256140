<template>
  <v-btn icon x-large v-on="$listeners">
    <v-avatar :size="size" v-bind="$attrs">
      <img v-if="image" :src="image"  alt="icon"/>
      <span class="white--text headline" v-else>
        {{ name[0].toUpperCase() }}
      </span>
    </v-avatar>
  </v-btn>
</template>

<script>

export default {
  props: {
    image: {
      type: String,
      default: undefined
    },

    name: {
      type: String,
      required: true
    }
  },
  created() {
    if(!this.$attrs.size) {
      this.size = 36
    }
  },
  data: () => ({
    size: undefined
  }),
  computed: {
    color() {
      return 'primary';
    }
  }
};
</script>
